import React, { useEffect, useState } from "react";
import cartLogo from "../../assets/images/cart.svg";
import "./cart-btn.less";

const CartButton = ({
  toggleCart,
  cartSize,
}: {
  toggleCart: () => void;
  cartSize: number;
}) => {
  return (
    <>
      <div className="cart-button-container" onClick={toggleCart}>
        <img src={cartLogo} alt="Cart Logo" className="cart-logo" />
        {cartSize > 0 && <div className="badge">{cartSize}</div>}
      </div>
    </>
  );
};

export default CartButton;
