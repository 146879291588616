import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import birds from '../../assets/images/bird.svg';
import logoWithWord from '../../assets/images/logo/logo-gold-with-word.svg';
import birdNestProduct from '../../assets/images/hero-birdnest.webp';

import './Hero.less';

const Hero = () => {
    const intl = useIntl();
    const heroInnerContainerRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (heroInnerContainerRef.current) {
                heroInnerContainerRef.current.classList.add('animate');
            }
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    // Determine if the current locale is Chinese
    const isChineseLocale = intl.locale === 'zh-CN';

    return (
        <div id="Home" className="hero-container">
            <div className="hero-content" ref={heroInnerContainerRef}>
                <div className="hero-logo-container">
                    <img src={logoWithWord} alt="logo" className="logo" />
                </div>
                <div className="top-container-hero">
                    <div className="top-left-container">
                        <div className="birds">
                            <img src={birds} alt="bird" className="bird1" />
                        </div>
                        <div className="hero-text">
                            <h1 className="hero-title">100<span>%</span></h1>
                            <p className={`hero-subtitle ${isChineseLocale ? 'hero-subtitle-zh' : ''}`}>
                                {intl.formatMessage({ id: "HERO_SLOGAN_1" })}<br />
                                {intl.formatMessage({ id: "HERO_SLOGAN_2" })}
                            </p>
                        </div>
                    </div>

                    <div className="top-right-container">
                        <div className="hero-product">
                            <img src={birdNestProduct} alt="birdnest product" className="product-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
