import React, { useEffect, useRef, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Card, Col, Row, Modal, Carousel, Button } from "antd";
import "./Shop.less";
import { salesContactLink, currencyCode } from "../../content/CompanyContent";
import Header from "../../components/header/header-top/Header";
import Footer from "../../components/footer/Footer";
import logo from "../../assets/images/logo/logo-gold-with-word.svg";
import WhatsappButton from "../../components/whatsapp-button/WhatsappButton";
import Cart from "../../components/cart/cart";
import HeaderCollapse from "../../components/header/header-collapse/HeaderCollapse";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import CartButton from "../../components/cart-btn/cart-btn";

const { Meta } = Card;

interface Product {
  id: number;
  name: string;
  price: string;
  quantity: number;
  mainPhoto: string;
  shortDesc: string;
}

const Product = () => {
  const navigate = useNavigate();

  const intl = useIntl();
  const [items, setItems] = useState<any[]>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [cartProduct, setCartProduct] = useState<Product[]>([]);
  const [productImages, setProductImages] = useState<string[]>([]);

  const shopLeafLeftRef = useRef<HTMLDivElement>(null);
  const shopLeafRightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchItems();
  }, [intl.locale]);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, items]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (shopLeafLeftRef.current) {
        shopLeafLeftRef.current.style.transform = `translateY(${
          scrollPosition * 0.4
        }px)`;
      }
      if (shopLeafRightRef.current) {
        shopLeafRightRef.current.style.transform = `translateY(${
          scrollPosition * 0.4
        }px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchItems();
  }, [intl.locale]);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, items]);

  const fetchItems = async () => {
    try {
      const itemsQuery = query(collection(db, "items"), orderBy("sequence"));
      const itemsSnapshot = await getDocs(itemsQuery);
      let itemsData = itemsSnapshot.docs.map((doc) => {
        const data = doc.data();
        const currentLocale = intl.locale;

        return {
          id: doc.id,
          name: data.name[currentLocale] || data.name["en"],
          shortDesc: data.shortDesc[currentLocale] || data.shortDesc["en"],
          longDesc: data.longDesc[currentLocale] || data.longDesc["en"],
          beforeDiscountPrice: data.beforeDiscountPrice || "",
          price: data.price || "",
          quantity: 1,
          sequence: data.sequence || 0, // Ensure you have a sequence field
        };
      });

      const itemsWithMainPhotos = await Promise.all(
        itemsData.map(async (item) => {
          const photosQuery = query(
            collection(db, "item-photos"),
            where("itemId", "==", item.id)
          );
          const photosSnapshot = await getDocs(photosQuery);
          const photos = photosSnapshot.docs.map((doc) => ({
            photoUrl: doc.data().photoUrl,
            isMain: doc.data().isMain,
          }));
          const mainPhoto =
            photos.find((photo) => photo.isMain)?.photoUrl ||
            photos[0]?.photoUrl ||
            "";
          return {
            ...item,
            mainPhoto,
            photos: photos.map((photo) => photo.photoUrl),
          };
        })
      );

      setItems(itemsWithMainPhotos);
      setFilteredItems(itemsWithMainPhotos);
    } catch (error) {
      console.error("Error fetching items: ", error);
    }
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    const lowercasedQuery = value.toLowerCase();
    const filteredData = items.filter(
      (item) =>
        item.name.toLowerCase().includes(lowercasedQuery) ||
        item.shortDesc.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredItems(filteredData);
  };

  const handleCardClick = (item: any) => {
    setSelectedProduct(item);
    setProductImages(item.photos || []);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setProductImages([]);
  };

  const handleBuyNow = (selectedProduct: any) => {
    navigate("/checkout", { state: { products: [selectedProduct] } });
  };

  const handleBuyNowWhatsapp = (selectedProduct: any) => {
    const whatsappUrl = `${salesContactLink}&text=${encodeURIComponent(
      `I would like to buy ${selectedProduct.name} x${selectedProduct.quantity}`
    )}`;
    window.open(whatsappUrl, "_blank");
    setIsModalOpen(false);
  };

  const handleCheckoutFromCart = () => {
    navigate("/checkout", { state: { products: cartProduct ?? [] } });
  };

  const handleAddToCart = (selectedProduct: any) => {
    setIsModalOpen(false);

    if (cartProduct == null) {
      setCartProduct([]);
    }

    const existingProductIndex = cartProduct.findIndex(
      (product: Product) => product.id === selectedProduct.id
    );

    if (existingProductIndex !== -1) {
      const updatedCartProduct = [...cartProduct];
      updatedCartProduct[existingProductIndex].quantity +=
        selectedProduct.quantity;

      setCartProduct(updatedCartProduct);
    } else {
      setCartProduct([...cartProduct, selectedProduct]);
    }

    setIsCartOpen(true);
  };

  const toggleIsCartOpen = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <>
      <Cart
        products={cartProduct ?? []}
        open={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        onUpdateCart={setCartProduct}
        onCheckout={handleCheckoutFromCart}
      />
      <div className="shop-page-wrapper">
        <div ref={shopLeafLeftRef} className="shop-leaf-left"></div>
        <div ref={shopLeafRightRef} className="shop-leaf-right"></div>
        <div className="shop-container">
          <Header isSubPage={true} />
          <HeaderCollapse isSubPage={true} />
          <div className="title-logo-container">
            <img src={logo} alt="Logo" className="logo" />
            <h1 className="shop-title">
              {intl.formatMessage({ id: "OUR_PRODUCTS_TITLE" })}
            </h1>
          </div>

          <div className="product-container">
            <Row gutter={[16, 16]}>
              {filteredItems.map((item) => (
                <Col key={item.id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    style={{ height: "100%" }}
                    hoverable
                    cover={<img alt={item.name} src={item.mainPhoto} />}
                    onClick={() => handleCardClick(item)}
                  >
                    <Meta
                      title={item.name}
                      description={
                        <div>
                          <p>{item.shortDesc}</p>
                          <br />
                          <p style={{ margin: 0 }}>
                            {item.beforeDiscountPrice ? (
                              <span>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "gray",
                                    marginRight: "8px",
                                  }}
                                >
                                  {currencyCode} {item.beforeDiscountPrice}
                                </span>
                                <span
                                  style={{
                                    fontSize: "1.2em",
                                    fontWeight: "bold",
                                    color: "red",
                                  }}
                                >
                                  {currencyCode} {item.price}
                                </span>
                              </span>
                            ) : (
                              <span style={{ fontWeight: "bold" }}>
                                {currencyCode} {item.price}
                              </span>
                            )}
                          </p>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>

            {selectedProduct && (
              <Modal
                title={selectedProduct.name}
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={null}
              >
                <div className="product-modal-content">
                  <Carousel arrows>
                    {productImages.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Product image ${index + 1}`}
                          style={{ width: "100%" }}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <br />
                  <p>
                    <strong>{selectedProduct.shortDesc}</strong>
                  </p>
                  <br />
                  <p>{selectedProduct.longDesc}</p>
                  <br />
                  <p>
                    {selectedProduct.beforeDiscountPrice ? (
                      <span>
                        <span
                          style={{
                            textDecoration: "line-through",
                            marginRight: "8px",
                          }}
                        >
                          {currencyCode} {selectedProduct.beforeDiscountPrice}
                        </span>
                        <span
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {currencyCode} {selectedProduct.price}
                        </span>
                      </span>
                    ) : (
                      <span style={{ fontWeight: "bold" }}>
                        {currencyCode} {selectedProduct.price}
                      </span>
                    )}
                  </p>
                  <div className="product-modal-footer">
                    <div
                      className="quantity-selector"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          setSelectedProduct({
                            ...selectedProduct,
                            quantity: Math.max(
                              1,
                              (selectedProduct.quantity || 1) - 1
                            ),
                          })
                        }
                        style={{
                          width: "32px",
                          height: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        -
                      </Button>
                      <span
                        className="quantity"
                        style={{ fontSize: "16px", fontWeight: "bold" }}
                      >
                        {selectedProduct.quantity || 1}
                      </span>
                      <Button
                        onClick={() =>
                          setSelectedProduct({
                            ...selectedProduct,
                            quantity: (selectedProduct.quantity || 1) + 1,
                          })
                        }
                        style={{
                          width: "32px",
                          height: "32px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        +
                      </Button>
                    </div>
                    {selectedProduct.price === "-" && (
                      <Button
                        onClick={() => handleBuyNowWhatsapp(selectedProduct)}
                        type="primary"
                        className="buy-now-btn"
                        style={{ marginLeft: "10px" }}
                      >
                        {intl.formatMessage({ id: "BUY_NOW" })}
                      </Button>
                    )}
                    {selectedProduct.price != "-" && (
                      <>
                        <Button
                          onClick={() => handleBuyNow(selectedProduct)}
                          type="primary"
                          className="buy-now-btn"
                          style={{ marginLeft: "10px" }}
                        >
                          {intl.formatMessage({ id: "BUY_NOW" })}
                        </Button>
                        <Button
                          onClick={() => handleAddToCart(selectedProduct)}
                          type="primary"
                          className="add-to-cart-btn"
                          style={{ marginLeft: "10px" }}
                        >
                          {intl.formatMessage({ id: "ADD_TO_CART" })}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Modal>
            )}
          </div>
          <WhatsappButton />
          <CartButton
            toggleCart={toggleIsCartOpen}
            cartSize={cartProduct.length}
          />
          <Footer isSubPage={true} />
        </div>
      </div>
    </>
  );
};

export default Product;
