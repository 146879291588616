"use client";

import { useState } from "react";
import { Button } from "antd";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const products = [
  {
    id: 1,
    name: "Throwback Hip Bag",
    href: "#",
    color: "Salmon",
    price: "$90.00",
    quantity: 1,
    imageSrc:
      "https://tailwindui.com/plus/img/ecommerce-images/shopping-cart-page-04-product-01.jpg",
    imageAlt:
      "Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt.",
  },
  {
    id: 2,
    name: "Medium Stuff Satchel",
    href: "#",
    color: "Blue",
    price: "$32.00",
    quantity: 1,
    imageSrc:
      "https://tailwindui.com/plus/img/ecommerce-images/shopping-cart-page-04-product-02.jpg",
    imageAlt:
      "Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.",
  },
  // More products...
];

interface Product {
  id: number;
  name: string;
  price: string;
  quantity: number;
  mainPhoto: string;
  shortDesc: string;
}

interface CartProps {
  products: Product[];
  open: boolean;
  onClose: () => void;
  onUpdateCart: (products: Product[]) => void;
  onCheckout: () => void;
}

export default function Cart({
  products,
  open,
  onClose,
  onUpdateCart,
  onCheckout,
}: CartProps) {
  const calculateTotalAmount = () => {
    return products.reduce((total: number, product: Product) => {
      return total + calculateProductAmount(product);
    }, 0);
  };

  const calculateProductAmount = (product: Product) => {
    return formatProductPrice(product) * product.quantity;
  };

  const formatProductPrice = (product: Product) => {
    let price = parseFloat(product.price.replace(/[^0-9.-]+/g, ""));

    if (isNaN(price)) {
      price = 0;
    }

    return price;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} className="relative z-[10000]">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-lg font-medium text-gray-900">
                        Shopping cart
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={onClose}
                          className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                        >
                          <span className="absolute -inset-0.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul
                          role="list"
                          className="-my-6 divide-y divide-gray-200"
                        >
                          {products.map((product) => (
                            <li key={product.id} className="flex py-6">
                              <div className="size-24 shrink-0 overflow-hidden rounded-md border border-gray-200">
                                <img
                                  alt={product.shortDesc}
                                  src={product.mainPhoto}
                                  className="size-full object-cover"
                                />
                              </div>

                              <div className="ml-4 flex flex-1 flex-col">
                                <div>
                                  <div className="flex justify-between text-base font-medium text-gray-900">
                                    <h3>{product.name}</h3>
                                    <p className="ml-4">
                                      RM
                                      {isNaN(
                                        parseFloat(
                                          product.price.replace(
                                            /[^0-9.-]+/g,
                                            ""
                                          )
                                        )
                                      )
                                        ? "0.00"
                                        : (
                                            parseFloat(
                                              product.price.replace(
                                                /[^0-9.-]+/g,
                                                ""
                                              )
                                            ) * product.quantity
                                          ).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-1 items-end justify-between text-sm">
                                  <div
                                    className="quantity-selector"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        product.quantity > 1
                                          ? onUpdateCart(
                                              products.map((p) =>
                                                p.id === product.id
                                                  ? {
                                                      ...p,
                                                      quantity: p.quantity - 1,
                                                    }
                                                  : p
                                              )
                                            )
                                          : onUpdateCart(
                                              products.filter(
                                                (p) => p.id !== product.id
                                              )
                                            );

                                        if (products.length === 0) {
                                          onClose();
                                        }
                                      }}
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      -
                                    </Button>
                                    <span
                                      className="quantity"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {product.quantity || 1}
                                    </span>
                                    <Button
                                      onClick={() => {
                                        const updatedProducts = products.map(
                                          (p) =>
                                            p.id === product.id
                                              ? {
                                                  ...p,
                                                  quantity: p.quantity + 1,
                                                }
                                              : p
                                        );

                                        onUpdateCart(updatedProducts);
                                      }}
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <div className="flex">
                                    <button
                                      type="button"
                                      className="font-medium text-admin-theme-dark hover:text-admin-theme"
                                      onClick={() => {
                                        onUpdateCart(
                                          products.filter(
                                            (p) => p.id !== product.id
                                          )
                                        );

                                        if (products.length === 0) {
                                          onClose();
                                        }
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>RM{calculateTotalAmount().toFixed(2)}</p>
                    </div>
                    <div className="mt-6">
                      <button
                        onClick={onCheckout}
                        className={`flex w-full items-center justify-center rounded-md border border-transparent px-6 py-3 text-base font-medium shadow-sm ${
                          products.length > 0
                            ? "bg-admin-theme-dark text-white hover:bg-admin-theme hover:text-black"
                            : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        }`}
                        disabled={products.length === 0}
                      >
                        Checkout
                      </button>
                    </div>
                    <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                      <p>
                        or{" "}
                        <button
                          type="button"
                          onClick={onClose}
                          className="font-medium text-admin-theme-dark hover:text-admin-theme"
                        >
                          Continue Shopping
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
