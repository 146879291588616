import { IntlProvider } from "react-intl";
import { Route, Routes, useLocation } from "react-router-dom";
import { SET_SCREENSIZE, useLayout } from "./hooks/LayoutContext";
import { useEffect } from "react";

import Content from "./pages/Content";

import messages_en from "./assets/i18n/en.json";
import message_zh_CN from "./assets/i18n/zh_CN.json";
import message_ms from "./assets/i18n/ms.json";

import "./App.less";
import Faq from "./pages/faq/Faq";
import Shop from "./pages/shop/Shop";
import ContactUs from "./pages/contact-us/ContactUs";
import AboutUs from "./pages/about-us/AboutUs";
import Checkout from "./pages/checkout/Checkout";

function App() {
  const { layoutState, layoutDispatch } = useLayout();
  const location = useLocation();

  const messages: any = {
    en: messages_en,
    "zh-CN": message_zh_CN,
    ms: message_ms,
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      layoutDispatch({ type: SET_SCREENSIZE, payload: dimension });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return (
    <IntlProvider
      locale={layoutState.locale}
      messages={layoutState.locale ? messages[layoutState.locale] : ""}
      key={layoutState.locale}
    >
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="*" element={<Content />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </IntlProvider>
  );
}

export default App;
