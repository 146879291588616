import React, { useEffect, useRef, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Card, Col, Row, Modal, Carousel, Button } from "antd";
import "./Checkout.less";
import { salesContactLink, currencyCode } from "../../content/CompanyContent";
import Header from "../../components/header/header-top/Header";
import Footer from "../../components/footer/Footer";
import logo from "../../assets/images/logo/logo-gold-with-word.svg";
import HeaderCollapse from "../../components/header/header-collapse/HeaderCollapse";
import { useIntl } from "react-intl";
import {
  Popover,
  PopoverBackdrop,
  PopoverButton,
  PopoverPanel,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/16/solid";
import {
  ChevronUpIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";
import PaymentMethodTab from "../../components/payment-method-tab/payment-method-tab";

const state_list = [
  { value: "Johor", name: "Johor" },
  { value: "Kedah", name: "Kedah" },
  { value: "Kelantan", name: "Kelantan" },
  { value: "Melaka", name: "Melaka" },
  { value: "Negeri Sembilan", name: "Negeri Sembilan" },
  { value: "Pahang", name: "Pahang" },
  { value: "Perak", name: "Perak" },
  { value: "Perlis", name: "Perlis" },
  { value: "Pulau Pinang", name: "Pulau Pinang" },
  { value: "Sabah", name: "Sabah" },
  { value: "Sarawak", name: "Sarawak" },
  { value: "Selangor", name: "Selangor" },
  { value: "Terengganu", name: "Terengganu" },
  {
    value: "Wilayah Persekutuan Kuala Lumpur",
    name: "Wilayah Persekutuan Kuala Lumpur",
  },
  { value: "Wilayah Persekutuan Labuan", name: "Wilayah Persekutuan Labuan" },
  {
    value: "Wilayah Persekutuan Putrajaya",
    name: "Wilayah Persekutuan Putrajaya",
  },
];

const flattenObject = (
  obj: any,
  parent = "",
  res: { [key: string]: any } = {}
) => {
  for (let key in obj) {
    const propName = parent ? `${parent}[${key}]` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item: any, index: number) => {
          flattenObject(item, `${propName}[${index}]`, res);
        });
      } else {
        flattenObject(obj[key], propName, res);
      }
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

interface Product {
  id: number;
  name: string;
  price: string;
  quantity: number;
  mainPhoto: string;
  shortDesc: string;
}

const Checkout = () => {
  const { state } = useLocation();
  const [selected, setSelected] = useState(state_list[0]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [discount, setDiscount] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles([]);

      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (files.length === 0) {
      alert("Please upload payment receipt.");

      return;
    }

    setLoading(true);

    const formDataToPost = new FormData();

    const formData = new FormData(event.currentTarget);

    const data = {
      voucher_code: voucherCode,
      recipient: {
        name: formData.get("recipient_name"),
        email: formData.get("recipient_email"),
        mobile_no: formData.get("recipient_mobile_no"),
        addr_line_1: formData.get("addr_line_1"),
        addr_line_2: formData.get("addr_line_2"),
        addr_line_3: formData.get("addr_line_3"),
        city: formData.get("city"),
        state: selected.value,
        postal_code: formData.get("postal_code"),
      },
      products: state?.products?.map((product: Product) => ({
        name: product.name,
        price: formatProductPrice(product),
        quantity: product.quantity,
        gross_amount: calculateProductAmount(product),
      })),
    };

    const flatCheckoutData = flattenObject(data);

    for (let key in flatCheckoutData) {
      formDataToPost.append(key, flatCheckoutData[key]);
    }

    Object.values(files).forEach((file, index) => {
      formDataToPost.append(`payment_receipt_img[${index}]`, file);
    });

    try {
      /* const response = await fetch(
        "http://localhost:8000/api/order/checkout", */
      const response = await fetch(
        "https://admin.forestbirdnest.com/api/order/checkout",
        {
          method: "POST",
          body: formDataToPost,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      alert(
        "Order placed successfully! You will receive a confirmation email shortly."
      );

      window.location.href = "/";
    } catch (error) {
      alert("Failed to place order. Please try again later.");
    }

    setLoading(false);
  }

  async function applyVoucher() {
    setLoading(true);

    const formDataToPost = new FormData();

    const formData = new FormData(
      document.querySelector("form") as HTMLFormElement
    );

    const data = {
      voucher_code: formData.get("voucher_code"),
      products: state?.products?.map((product: Product) => ({
        name: product.name,
        price: formatProductPrice(product),
        quantity: product.quantity,
        gross_amount: calculateProductAmount(product),
      })),
    };

    const flatCheckoutData = flattenObject(data);

    for (let key in flatCheckoutData) {
      formDataToPost.append(key, flatCheckoutData[key]);
    }

    try {
      /* const response = await fetch(
        "http://localhost:8000/api/order/apply_voucher", */
      const response = await fetch(
        "https://admin.forestbirdnest.com/api/order/apply_voucher",
        {
          method: "POST",
          body: formDataToPost,
        }
      );

      if (!response.ok) {
        setDiscount(0);
        setVoucherCode("");

        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status && (result.discount_amount ?? 0) > 0) {
        setDiscount(result.discount_amount ?? 0);
        setVoucherCode((data.voucher_code ?? "").toString().toUpperCase());

        alert("Successfully applied voucher!");
      } else {
        setDiscount(0);
        setVoucherCode("");

        alert("Failed to apply voucher. Please try again later.");
      }
    } catch (error) {
      setDiscount(0);
      setVoucherCode("");

      alert("Failed to apply voucher. Please try again later.");
    }

    setLoading(false);
  }

  const calculateTotalAmount = (
    includeShipping: boolean = true,
    includeDiscount: boolean = true
  ) => {
    var item_total = state?.products?.reduce(
      (total: number, product: Product) => {
        return total + calculateProductAmount(product);
      },
      0
    );

    return (
      item_total + (includeShipping ? 10 : 0) - (includeDiscount ? discount : 0)
    );
  };

  const calculateProductAmount = (product: Product) => {
    return formatProductPrice(product) * product.quantity;
  };

  const formatProductPrice = (product: Product) => {
    let price = parseFloat(product.price.replace(/[^0-9.-]+/g, ""));

    if (isNaN(price)) {
      price = 0;
    }

    return price;
  };

  return (
    <div className="checkout-page-wrapper">
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <div className="flex items-center space-x-2">
              <svg
                className="animate-spin h-5 w-5 text-admin-theme"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="checkout-container">
        <Header isSubPage={true} />
        <HeaderCollapse isSubPage={true} />
        <div className="title-logo-container">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="shop-title"></h1>
        </div>
        <div className="bg-white">
          {/* Background color split screen for large screens */}
          <div
            aria-hidden="true"
            className="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block"
          />
          <div
            aria-hidden="true"
            className="fixed right-0 top-0 hidden h-full w-1/2 bg-gray-50 lg:block"
          />

          <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
            <h1 className="sr-only">Order information</h1>

            <section
              aria-labelledby="summary-heading"
              className="bg-gray-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
            >
              <div className="mx-auto max-w-lg lg:max-w-none">
                <h2
                  id="summary-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Order summary
                </h2>

                <ul
                  role="list"
                  className="divide-y divide-gray-200 text-sm font-medium text-gray-900"
                >
                  {state?.products?.map(
                    (product: {
                      id: number;
                      photos: string[] | null;
                      name: string;
                      price: string;
                      quantity: number;
                    }) => (
                      <li
                        key={product.id}
                        className="flex items-start space-x-4 py-6"
                      >
                        <img
                          src={
                            product.photos && product.photos.length > 0
                              ? product.photos[0]
                              : ""
                          }
                          className="size-20 flex-none rounded-md object-cover"
                        />
                        <div className="flex-auto space-y-1">
                          <h3>{product.name}</h3>
                          <span>x {product.quantity}</span>
                        </div>
                        <p className="flex-none text-base font-medium">
                          RM
                          {isNaN(
                            parseFloat(product.price.replace(/[^0-9.-]+/g, ""))
                          )
                            ? "0.00"
                            : (
                                parseFloat(
                                  product.price.replace(/[^0-9.-]+/g, "")
                                ) * product.quantity
                              ).toFixed(2)}
                        </p>
                      </li>
                    )
                  )}
                </ul>

                <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Subtotal</dt>
                    <dd>RM{calculateTotalAmount(false, false).toFixed(2)}</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt className="text-gray-600">Shipping</dt>
                    <dd>RM10.00</dd>
                  </div>
                  {discount > 0 && (
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">
                        Discount {voucherCode && <span>({voucherCode})</span>}
                      </dt>
                      <dd>-RM{discount.toFixed(2)}</dd>
                    </div>
                  )}
                  <div className="flex items-center justify-between pt-6">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base">
                      RM{calculateTotalAmount().toFixed(2)}
                    </dd>
                  </div>
                </dl>

                <Popover className="fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden">
                  <div className="relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6">
                    <div className="mx-auto max-w-lg">
                      <PopoverButton className="flex w-full items-center py-6 font-medium">
                        <span className="mr-auto text-base">Total</span>
                        <span className="mr-2 text-base">
                          RM{calculateTotalAmount().toFixed(2)}
                        </span>
                        {/* <ChevronUpIcon
                          aria-hidden="true"
                          className="size-5 text-gray-500"
                        /> */}
                      </PopoverButton>
                    </div>
                  </div>

                  <PopoverBackdrop
                    transition
                    className="fixed inset-0 bg-black/25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0 z-100"
                  />

                  <PopoverPanel
                    transition
                    className="relative z-10 transform bg-white px-4 py-6 transition duration-300 ease-in-out data-[closed]:translate-y-full sm:px-6"
                  >
                    <dl className="mx-auto max-w-lg space-y-6">
                      <div className="flex items-center justify-between">
                        <dt className="text-gray-600">Subtotal</dt>
                        <dd>
                          RM{calculateTotalAmount(false, false).toFixed(2)}
                        </dd>
                      </div>

                      <div className="flex items-center justify-between">
                        <dt className="text-gray-600">Shipping</dt>
                        <dd>RM10.00</dd>
                      </div>
                      {discount > 0 && (
                        <div className="flex items-center justify-between">
                          <dt className="text-gray-600">Discount</dt>
                          <dd>-RM{discount.toFixed(2)}</dd>
                        </div>
                      )}
                    </dl>
                  </PopoverPanel>
                </Popover>
              </div>
            </section>

            <form
              className="px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16"
              onSubmit={handleSubmit}
            >
              <div className="rounded-md bg-blue-50 p-4">
                <dl className="space-y-6 pt-4 pb-4 text-sm font-medium text-gray-900 lg:block">
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="voucher_code"
                      className="block text-sm/6 font-medium text-gray-700"
                    >
                      Voucher Code (If applicable)
                    </label>
                    <div className="mt-2 flex items-center justify-between">
                      <input
                        id="voucher_code"
                        name="voucher_code"
                        type="text"
                        placeholder="Do you have a voucher code?"
                        className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                      <button
                        type="button"
                        className="ml-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-admin-theme focus:ring-offset-2 focus:ring-offset-gray-50"
                        onClick={applyVoucher}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </dl>
              </div>
              <div className="mx-auto max-w-lg lg:max-w-none">
                <section aria-labelledby="shipping-heading" className="mt-10">
                  <h2
                    id="shipping-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    Shipping info
                  </h2>

                  <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="recipient_name"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="recipient_name"
                          name="recipient_name"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          required
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="recipient_email"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="recipient_email"
                          name="recipient_email"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          required
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <div>
                        <label
                          htmlFor="recipient_mobile_no"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Mobile No <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="mt-2 flex">
                          <div className="flex shrink-0 items-center rounded-l-md bg-white px-3 text-base text-gray-500 outline outline-1 -outline-offset-1 outline-gray-300 sm:text-sm/6">
                            +60
                          </div>
                          <input
                            id="recipient_mobile_no"
                            name="recipient_mobile_no"
                            type="tel"
                            placeholder="123456789"
                            className="-ml-px block w-full grow rounded-r-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <div className="rounded-md bg-yellow-50 p-4">
                        <div className="flex">
                          <div className="shrink-0">
                            <ExclamationTriangleIcon
                              aria-hidden="true"
                              className="size-5 text-yellow-400"
                            />
                          </div>
                          <div className="ml-3">
                            <div className="text-sm text-yellow-700">
                              <p>
                                <strong>
                                  We are able to serve customers in West
                                  Malaysia only for now, please make sure the
                                  delivery address is located in West Malaysia
                                  ONLY.
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="addr_line_1"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        Address Line 1 <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="addr_line_1"
                          name="addr_line_1"
                          type="text"
                          autoComplete="street-address"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          required
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="addr_line_2"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        Address Line 2
                      </label>
                      <div className="mt-2">
                        <input
                          id="addr_line_2"
                          name="addr_line_2"
                          type="text"
                          autoComplete="street-address"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="addr_line_3"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        Address Line 3
                      </label>
                      <div className="mt-2">
                        <input
                          id="addr_line_3"
                          name="addr_line_3"
                          type="text"
                          autoComplete="street-address"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="region"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        State <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-2">
                        <Listbox value={selected} onChange={setSelected}>
                          <div className="relative mt-2">
                            <ListboxButton className="grid w-full cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 z-10">
                              <span className="col-start-1 row-start-1 truncate pr-6">
                                {selected.name}
                              </span>
                              <ChevronUpDownIcon
                                aria-hidden="true"
                                className="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                              />
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                              {state_list.map((state) => (
                                <ListboxOption
                                  key={state.value}
                                  value={state}
                                  disabled={
                                    state.value === "Sarawak" ||
                                    state.value ===
                                      "Wilayah Persekutuan Labuan" ||
                                    state.value === "Sabah"
                                  }
                                  className={`group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none`}
                                >
                                  <span
                                    className={`block truncate font-normal group-data-[selected]:font-semibold ${
                                      state.value === "Sarawak" ||
                                      state.value ===
                                        "Wilayah Persekutuan Labuan" ||
                                      state.value === "Sabah"
                                        ? "text-gray-400"
                                        : ""
                                    }`}
                                  >
                                    {state.name}
                                  </span>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="size-5"
                                    />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        City <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="city"
                          name="city"
                          type="text"
                          autoComplete="address-level2"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          required
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-1">
                      <label
                        htmlFor="postal_code"
                        className="block text-sm/6 font-medium text-gray-700"
                      >
                        Postal Code <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="postal_code"
                          name="postal_code"
                          type="text"
                          autoComplete="postal-code"
                          className="block w-full rounded-md bg-white px-3 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section aria-labelledby="payment-heading" className="mt-10">
                  <h2
                    id="payment-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    Payment <span style={{ color: "red" }}>*</span>
                  </h2>
                  <div className="rounded-md bg-blue-50 p-4 mt-5 mb-5">
                    <div className="flex">
                      <div className="shrink-0">
                        <InformationCircleIcon
                          aria-hidden="true"
                          className="size-8 text-blue-400"
                        />
                      </div>
                      <div className="ml-3">
                        <h1 className="text-blue-800">
                          <strong style={{ fontSize: "1.25rem" }}>
                            How to Make Payment:
                          </strong>
                        </h1>
                        <ol className="list-decimal pl-5 text-sm text-blue-700">
                          <li
                            style={{
                              fontSize: "1.1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Select your preferred payment QR, and screenshot the
                            QR code.
                          </li>
                          <li
                            style={{
                              fontSize: "1.1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Open any E-Wallet (Duit Now QR, Touch n GO, etc),
                            and click Scan from the Gallery, select the QR code
                            you have screenshot.
                          </li>
                          <li
                            style={{
                              fontSize: "1.1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Key in the amount total shown in your cart.
                          </li>
                          <li
                            style={{
                              fontSize: "1.1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Make payment and screenshot your receipt.
                          </li>
                          <li
                            style={{
                              fontSize: "1.1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Upload your proof of payment in the column below.
                          </li>
                          <li
                            style={{
                              fontSize: "1.1rem",
                              marginBottom: "0.5rem",
                            }}
                          >
                            Submit your order and we will verify your payment!
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <PaymentMethodTab />

                  <div className="mt-6 grid grid-cols-3 gap-x-4 gap-y-6 sm:grid-cols-4">
                    <div className="col-span-3 sm:col-span-4">
                      <div style={{ marginBottom: "10px" }}>
                        <span>Upload payment receipt:</span>
                      </div>
                      <div className="flex items-center justify-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                              or drag and drop
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            accept="image/*"
                            className="hidden"
                            multiple
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="preview-gallery mt-4 grid grid-cols-3 gap-4">
                    {files.map((file, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Preview ${index}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                      </div>
                    ))}
                  </div>
                </section>

                <div className="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-end">
                  <button
                    type="button"
                    className="cancel-order-btn w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-admin-theme focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-auto"
                    onClick={() => window.history.back()}
                  >
                    Cancel and Return to Shop
                  </button>
                  <button
                    type="submit"
                    className="confirm-order-btn w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-admin-theme focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-auto"
                  >
                    Confirm Order
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer isSubPage={true} />
      </div>
    </div>
  );
};

export default Checkout;
